































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import UploadError from "@/components/UploadError.vue";
import {setPageTitle} from "@/utils/meta";
import Card from "@/components/Card.vue";
import formatter from "@/mixins/formatter";

@Component({
  components: {Card, UploadError}, 
  mixins: [formatter]})
export default class StockSetting extends Vue {

  @Prop() indexId!: number;
  @Prop() stockId!: number;
  @Prop() stockName!: string;
  error = null;
  uploadingData = false;
  doneUpload = false;
  companyId= 0;
  countryId= null;
  seuil=null;
  startVariation= null; //new Date();
  menu = false;
  companies=[];
  selectedCompanies=[];
  countries = [];
  loading = true;
  exist = false;
  update = false;


  created() {
      setPageTitle("Stock Setting ");

      this.loadComposition();
      this.loadCompanies();
      this.$api.get("country?per-page=0").then(j => (this.countries = j.data));

  }

  loadCompanies() {
     return this.$api.get(`company/list?stock=${this.stockId}`).then(res => {
          this.companies = res.data;
          this.loading = false;
      });
  }

  loadComposition() {
      return  this.$api.get(`stock-exchange/list-composition?stock=${this.stockId}`).then(res => {
          this.selectedCompanies = res.data.listIndex.map(item => ({'id' : item.company.id,'name': item.company.name ,'symbol':item.company.symbol}));
          this.seuil= res.data.seuil;
          this.countryId= res.data.country_id;
          this.startVariation= res.data.startVariation;
          if(this.selectedCompanies.length > 0){
              this.exist = true;
          }
          //this.loadCompanies();
      });
  }
  addCompany() {

      const selectedCompany = this.companies.find(company => company.id === this.companyId);
      // Check if the company with the same ID already exists in selectedCompanies
      const exists = this.selectedCompanies.some(company => company.id === this.companyId);
      this.doneUpload = false;
      // If the company doesn't exist in selectedCompanies, add it
      if (selectedCompany && !exists) {
          if(this.exist){
              this.uploadingData = true;

              this.$api.post(`stock-exchange/add-company?index=${this.indexId}&company=${this.companyId}`).
              then(response => {
                  this.doneUpload = true;
              }).catch((e) => {
                  this.error = e.response.data;
              }).finally(() => {
                  this.uploadingData = false;
                  //this.doneUpload = false;
                  //this.loadComposition();
              });
          }
          this.selectedCompanies.push({
          id: selectedCompany.id,
          name: selectedCompany.name, 
          symbol: selectedCompany.symbol
          });
      }else{
          $msg='company exist deja';
      }
      this.companyId = null;
      console.log('jannt',this.selectedCompanies);
  }
  deleteCompany(id) {
      this.doneUpload = false;
      this.$root.$confirm
      .open(
          "Delete company",
          "Are you sure you want to delete this company ?",
          {}
      )
      .then(confirm => {
          if (confirm) {
              if(this.exist){
                  this.uploadingData = true;
                  this.$api.post(`stock-exchange/remove-company?index=${this.indexId}&company=${id}`).
                  then(response => {
                      this.doneUpload = true;
                  }).catch((e) => {
                      this.error = e.response.data;
                  }).finally(() => {
                      this.uploadingData = false;
                      this.loadComposition();
                      //this.doneUpload = false;
                  });
               }
              this.selectedCompanies = this.selectedCompanies.filter(company => company.id !== id);
              if(this.selectedCompanies.length == 0){
                  this.exist = false;
              }
          }
      });
  }
  updateSeuil(seuil){
      this.seuil = seuil;
  }
  uploadData() {
      this.uploadingData = true;
      this.update = false;

      const symbols = this.selectedCompanies.map(company => company.symbol);
      if(this.exist){
          this.update = true;
      }
      console.log('ffff',this.exist,this.update )
      this.error = null;

      this.$api.post(`stock-exchange/integrate-index?stock=${this.stockId}&seuil=${this.seuil}&update=${this.update}&country=${this.countryId}&dateStart=${this.startVariation}`, {
          composition: symbols,
      }).then(response => {
              this.uploadingData = false;
              this.doneUpload = true;
          }).catch((e) => {
              this.error = e.response.data;
              this.uploadingData = false;
          }).finally(() => {
              this.loadComposition();
              this.doneUpload = false;
              // window.location.reload();
          });
  }

  importFScompanies() {
      this.uploadingData = true;

      const symbols = this.selectedCompanies.map(company => company.symbol);
      this.error = null;

      this.$api.post(`company/fmp-financial?nbrYear=1&externe=true`, {
          symbol: symbols,
      }).then(response => {
  
      }).catch((e) => {
          this.error = e.response.data;
      }).finally(() => {
          this.uploadingData = false;
          this.doneUpload = true ;
      });
  }

  viewCompany(id) {
      const routePath = `/company/${id}`;
      // Use window.open to open the route in a new tab
      window.open(routePath, '_blank');
  //this.$router.push(`/company/${id}`);
  }

  viewIndex() {
      const routePath = `/stock/${this.indexId}`;
      window.open(routePath, '_blank');
  }
}
